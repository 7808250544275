<template>
    <v-container style="color: white">
        <base-hero-simple />
        <div class="container-bg" style="margin-top: 1%; margin: auto">
            <div class="container-fg help-and-support">
                <v-tabs dark class="request-tab-top">
                    <v-tab
                        ><div class="text-h7 nav-font"
                            >TECH SUPPORT FAQ</div
                        ></v-tab
                    >
                    <v-tab
                        ><div class="text-h7 nav-font"
                            >TECH SUPPORT CONTACT</div
                        ></v-tab
                    >
                    <v-tab-item>
                        <v-divider
                            style="margin-bottom: 1%; border-color: gray"
                        />
                        <div id="card-elements">
                            <!-- <base-info-card
                            title="Getac E-Store"
                            space="10"
                            color="primary"
                        >
                            To purchase parts please visit our e-store at
                            <a href="https://store.getac.us" target="_blank"
                                >https://store.getac.us</a
                            >
                        </base-info-card> -->
                            <base-info-card
                                title="Tech Support FAQ"
                                space="10"
                                color="primary"
                            >
                            </base-info-card>
                            <v-row style="margin-top: 5px">
                                <v-col cols="10">
                                    <v-text-field
                                        v-model="serialNumber"
                                        outlined
                                        dark
                                        label="Enter Serial Number"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn
                                        color="primary"
                                        large
                                        elevation="0"
                                        outlined
                                        style="margin-top: 5px"
                                        @click="queryFaq"
                                        >Search</v-btn
                                    >
                                </v-col>
                            </v-row>
                            <v-progress-circular
                                v-if="loading"
                                style="display: block; margin: auto"
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                            <h1 v-if="noResults"
                                >No FAQ items found. Please enter another serial
                                number.</h1
                            >
                            <div
                                v-for="(item, index) in faqList"
                                :key="index"
                                class="faq-section"
                            >
                                <base-info-card
                                    :title="item.groupName"
                                    space="10"
                                    color="primary"
                                    style="margin-top: 15px"
                                >
                                </base-info-card>
                                <v-expansion-panels
                                    v-for="(items, i) in item.items"
                                    :key="i"
                                    class="expanded-panels"
                                    dark
                                    accordion
                                >
                                    <v-expansion-panel>
                                        <v-expansion-panel-header
                                            class="expansion-header-first"
                                        >
                                            {{ items.FAQ_QUESTION }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content
                                            class="faq-content"
                                            v-html="items.FAQ_ANSWER"
                                        >
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </div>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div id="card-elements">
                            <base-info-card
                                title="
                                    Technical & Service Support "
                                space="10"
                                subtitle="Get in contact with our team."
                                color="primary"
                            >
                                <sf-form
                                    v-if="$auth.isAuthenticated"
                                    private-form
                                    outlined
                                    dense
                                    style="margin-top: 15px"
                                />
                                <div v-else>
                                    Please
                                    <a href="" @click.prevent @click="login"
                                        >sign in</a
                                    >
                                    to submit a service request
                                </div>
                            </base-info-card>
                        </div>
                    </v-tab-item>
                </v-tabs>
            </div>
        </div>
    </v-container>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import sfForm from '@/components/support/sf_form'
import gql from 'graphql-tag'

export default {
    name: `HelpSupport`,

    metaInfo: { title: `Help & Support` },

    components: {
        sfForm
    },

    props: {
        id: {
            type: String,
            default: `about`
        }
    },

    data: () => ({
        localUser: {},
        serialNumber: '',
        faqList: [],
        selectedCategory: '',
        loading: false,
        noResults: false
    }),

    computed: {
        user: {
            get() {
                return cloneDeep(this.$auth.user)
            },
            set(value) {
                this.localUser = value
            }
        }
    },
    methods: {
        login() {
            this.$router.push(`/auth-help-and-support`)
        },
        queryFaq() {
            this.faqList = []
            this.noResults = false
            this.loading = true
            this.$apollo
                .query({
                    query: gql`
                        query queryURL($url: String!, $method: String) {
                            queryURL(url: $url, method: $method)
                        }
                    `,
                    variables: {
                        url: `https://rma.getac.com/MSS/commonReportAction.do?method=getReportJson&reportId=254&funcId=M1415_QUERY_FAQ_BY_SN&SN=${this.serialNumber}`,
                        method: 'GET'
                    }
                })
                .then((data) => {
                    const parsed = JSON.parse(data.data.queryURL)

                    const groupById = (arr = []) => {
                        let result = []
                        result = arr.reduce((r, a) => {
                            r[a.FAQ_GROUP_NAME] = r[a.FAQ_GROUP_NAME] || []
                            r[a.FAQ_GROUP_NAME].push(a)
                            return r
                        }, [])
                        return result
                    }
                    const grouped = groupById(parsed.result)
                    const final = Object.keys(grouped).map((e) => ({
                        groupName: e,
                        items: grouped[e]
                    }))
                    if (final.length === 0) {
                        this.noResults = true
                    } else {
                        this.faqList = final
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style>
.faq-content {
    padding: 10px 25px;
    background-color: rgb(78, 78, 78);
}
.help-and-support .theme--light.v-tabs-items {
    background-color: transparent !important;
    padding: 25px;
}
</style>
